.appLayout {
    min-height: 100vh;
}

.iconCollapse {
    font-size: 16px;
    width: 64px !important;
    height: 64px;
}

.appHeader {
    background-color: #ffffff;
    padding: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    box-shadow: 0 4px 2px -2px #f3f3f3;
}

.avatar {
    width: 36px;
    height: 36px;
    margin: calc((64px - 36px) / 2);
    border-radius: 50%;
    cursor: pointer;
}

.content {
    background-color: #ffffff;
    min-height: calc(100vh - 64px);
}

.page {
    overflow-y: scroll;
    height: calc(100vh - 112px);
    padding-left: 10px;
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}