.container {
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.loginBox {
    width: min(100vw, 400px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid whitesmoke;
}

.passwordLine {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.passwordLine > * {
    color: #888 !important;
}

.h {
    color: #444;
    margin-bottom: 25px;
}

.loginButton {
    width: 100%;
    margin: 35px;
}

.input {
    margin: 15px 0 5px 0;
}

.forgotPassword > a {
    text-decoration: none;
}
