.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
}

.lds-ellipsis div {
    position: absolute;
    top: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgb(0, 183, 255);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 14px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 14px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 38px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 62px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.tts-spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 2147483647;

    height: 100vh;
    width: 100vw;
    cursor: wait;
    background-color: rgba(240, 240, 240, 0.6);
    transition: 0.2s;

    font-size: 1.6em;
    font-family: sans-serif;
    font-weight: 700;
    color: rgb(0, 183, 255);
    padding-bottom: 80px;
}

.ts-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90px;
    height: 90px;
    transform: rotate(45deg);
}

.ts-border {
    border-radius: 5px;
    border: 3px solid rgba(0, 183, 255, 0.6);
}

.ts-window {
    transform: rotate(-45deg);
}
