.ant-input-prefix:not(#edit-my-info .ant-input-prefix) {
    opacity: 0.3;
}

.app-box-shadow {
    background-color: rgba(245, 245, 245, 0.5);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
